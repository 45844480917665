export class StringUtils {
  /**
   *  Globally Unique Identifier - RFC 4122 - https://www.ietf.org/rfc/rfc4122.txt
   */
  static guidV4Generator(): string {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char: string): string => {
      // tslint:disable-next-line: no-bitwise
      const random = (Math.random() * 16) | 0;
      const value = char === 'x' ? random : (random % 4) + 8;
      return value.toString(16);
    });

    return uuid;
  }

  /**
   * Takes a string, capitalises each word and removes spaces
   */
  static capitaliseEachFirstLetter(text: string): string {
    return text
      .toLowerCase()
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('');
  }

  /**
   * Takes a string array, creates one string with each element separated by a comma & 'or' before last word.
   */
  static ListString(originalString = '', listOfStrings: string[]): string {
    if (listOfStrings.length === 1) {
      originalString += `${listOfStrings[0]}.`;
    } else if (listOfStrings.length > 1) {
      const lastService = listOfStrings.pop();
      originalString += `${listOfStrings.join(', ')} or ${lastService}.`;
    } else {
      originalString = '';
    }

    return originalString;
  }
}
