import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { APP_ROUTES } from '@portal-app/advisor/routes';
import { Department } from '@portal-shared/models/department';
import { DepartmentFeatures } from '@portal-shared/models/enums/department-features';
import { NO_LINK_ERROR } from '@portal-shared/models/enums/error.enum';
import { STORAGE_KEYS } from '@portal-shared/models/enums/storage-keys.enum';
import { DepartmentService } from '@portal-shared/services/department.service';
import { ErrorService } from '@portal-shared/services/error.service';
import { Error, STORE_FIELDS } from '@portal-store/models/store.model';
import { StoreService } from '@portal-store/store.service';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class DepartmentResolver {
  role: string;
  constructor(
    private departmentService: DepartmentService,
    private store: StoreService,
    private router: Router,
    private errorService: ErrorService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Department> | Observable<never> {
    const department: Department = this.store.get(STORE_FIELDS.department);
    if (!department) {
      const departmentShortName: string = this.getDepartmentShortName();
      if (departmentShortName) {
        localStorage.setItem(STORAGE_KEYS.departmentName, departmentShortName);
        return this.requestDepartment(departmentShortName, state);
      }

      this.router.navigateByUrl(APP_ROUTES.selectDepartment);
    }
    return of(department);
  }

  private toggleError(errorMessage: Error, error: HttpErrorResponse = null): void {
    this.store.add(STORE_FIELDS.error, this.errorService.buildError(errorMessage, error));
  }

  private getDepartmentShortName(): string {
    return localStorage.getItem(STORAGE_KEYS.departmentName);
  }

  private requestDepartment(
    departmentName: string,
    state: RouterStateSnapshot,
  ): Observable<Department> | Observable<never> {
    return this.departmentService.getDepartment(departmentName).pipe(
      catchError((error: HttpErrorResponse) => {
        this.toggleError(NO_LINK_ERROR(), error);
        this.store.add(STORE_FIELDS.loadingInitial, null);
        return throwError(error);
      }),
      switchMap((department: Department) => {
        this.store.add(STORE_FIELDS.department, { ...department });
        localStorage.setItem(STORAGE_KEYS.organisationId, department.organisationId);
        if (
          !department?.features?.includes(DepartmentFeatures.PortalCustomers) &&
          !department?.features?.includes(DepartmentFeatures.WorkReportHybrid) &&
          state.url.includes(APP_ROUTES.customers)
        ) {
          this.router.navigateByUrl(APP_ROUTES.home);
          return EMPTY;
        } else if (
          !department?.features?.includes(DepartmentFeatures.CreditReportPortalDirect) &&
          state.url.includes(APP_ROUTES.creditReport)
        ) {
          this.router.navigateByUrl(APP_ROUTES.customers + '/' + APP_ROUTES.list);
          return EMPTY;
        } else if (
          !department?.features?.includes(DepartmentFeatures.PepsAndSanctions) &&
          state.url.includes(APP_ROUTES.sanctionReport)
        ) {
          this.router.navigateByUrl(APP_ROUTES.customers + '/' + APP_ROUTES.list);
          return EMPTY;
        } else if (
          !department?.features?.includes(DepartmentFeatures.IdDocVerification) &&
          state.url.includes(APP_ROUTES.idDocVerification)
        ) {
          this.router.navigateByUrl(APP_ROUTES.customers + '/' + APP_ROUTES.list);
          return EMPTY;
        } else if (
          !department?.features?.includes(DepartmentFeatures.CreditSearch) &&
          state.url.includes(APP_ROUTES.creditSummary)
        ) {
          this.router.navigateByUrl(APP_ROUTES.customers + '/' + APP_ROUTES.list);
          return EMPTY;
        } else if (
          department?.features?.includes(DepartmentFeatures.WorkReportHybrid) &&
          !state.url.includes(APP_ROUTES.hybridPayroll)
        ) {
          this.router.navigateByUrl(APP_ROUTES.workReport);
          return EMPTY;
        } else if (
          !department?.features?.includes(DepartmentFeatures.WorkReportHybrid) &&
          state.url.includes(APP_ROUTES.workReport)
        ) {
          this.router.navigateByUrl(APP_ROUTES.home);
          return EMPTY;
        }
        return of(department);
      }),
    );
  }
}
