import { Error } from '@portal-store/models/store.model';

export const enum ERROR_HEADINGS {
  standard = 'Sorry, something went wrong.',
  noCustomer = 'No customer(s) found.',
  noApplications = 'No application(s) found.',
  noCustomerDetails = 'Sorry, something went wrong loading customer.',
  noApplication = 'Sorry, something went wrong loading application.',
  noInsights = 'Insights currently not available',
  noEmploymentDetails = 'Employment details currently not available',
  noApplicationOverview = 'Application overview currently not available',
  noTransactions = 'Please note the connected account returned no transactions.',
  downloadDropdownValidation = 'Please select a download type',
  reportDownloadDropdownValidation = 'Please select a month',
  noCreditReport = 'No Credit report available to show.',
  noIdDocumentVerificationReport = 'No Identity Document Report to show.',
  noSanctionChecks = 'No PEPs and Sanctions report to show. Please run the PEPs and Sanctions check.',
  sanctionCheckUnderProcess = 'The PEPs and Sanctions report will be available shortly. Please check back in a few minutes.',
  internalServerError = 'Internal Server Error',
  noDecisionSanctionCheck = 'Mandatory fields are missing in the request. Unable to perform the sanction screening.',
  noDecisionIdDocumentVerificationCheck = 'Mandatory fields are missing in the request. Unable to perform the Id Document Verification screening.',
  noDocumentFoundIdDocumentVerificationCheck = 'No Document Found',
  noDocumentImageFoundIdDocumentVerificationCheck = 'No Document Image Found',
  employerNotValid = 'Please provide a valid input.',
  weAreSorry = 'We are sorry.',
  tryAgainLater = 'Unfortunately, we are unable to complete your request. Please try again later.',
  creditScoreLessThanForty = `Unfortunately, we're unable to confirm your customer's identity electronically so we're unable to provide you with their credit report.`,
  noDepartmentsAvailable = 'Unfortunately, there are no departments available.',
  noDecision = '',
  noPersonsFound = 'No record(s) found.',
  reportDownloadMissingData = 'We are unable to generate this report because the running balance for the transactions could not be calculated accurately. Please be advised to look for an alternative option to get this data.',
  reportDownloadOtherError = 'We are unable to generate this report. Please try again later.',
}

export const enum ERROR_LINK_TEXT {
  standard = 'Go back and try again.',
  secondary = 'Please try again.',
  search = 'Please search again.',
  tryLater = 'Please try again later.',
  refresh = 'Refresh',
}

export const enum CUSTOMER_FORM_ERROR_TEXT {
  saveCustomerTechnicalError = 'Sorry, something went wrong. Please try pressing [Save customer] again.',
  saveCustomerEmptyForm = 'Please fill in the required fields, before pressing [Save customer].',
}

export const CUSTOMER_SUBMISSION_ERROR: Error = {
  heading: CUSTOMER_FORM_ERROR_TEXT.saveCustomerTechnicalError,
};

export const CUSTOMER_EMPTY_ERROR: Error = {
  heading: CUSTOMER_FORM_ERROR_TEXT.saveCustomerEmptyForm,
};

export const REPORT_DOWNLOAD_MISSING_DATA: Error = {
  heading: ERROR_HEADINGS.reportDownloadMissingData,
};

export const REPORT_DOWNLOAD_OTHER_ERROR: Error = {
  heading: ERROR_HEADINGS.reportDownloadOtherError,
};

export const STANDARD_ERROR = (url: string): Error => {
  return {
    heading: ERROR_HEADINGS.standard,
    linkText: ERROR_LINK_TEXT.standard,
    errorLink: url,
  };
};

export const SEARCH_AGAIN_ERROR = (headingError: ERROR_HEADINGS): Error => {
  return {
    heading: headingError,
    subText: ERROR_LINK_TEXT.search,
  };
};

export const TRY_AGAIN_ERROR = (url?: string): Error => {
  return {
    heading: ERROR_HEADINGS.standard,
    linkText: ERROR_LINK_TEXT.secondary,
    errorLink: url || null,
  };
};

export const CREDIT_SCORE_LESS_THAN_FORTY: Error = {
  heading: ERROR_HEADINGS.creditScoreLessThanForty,
};

export const NO_INSIGHTS_ERROR = (): Error => {
  return {
    heading: ERROR_HEADINGS.noInsights,
    linkText: ERROR_LINK_TEXT.secondary,
  };
};

export const NO_EMPLOYMENT_DETAILS_ERROR = (): Error => {
  return {
    heading: ERROR_HEADINGS.noEmploymentDetails,
    linkText: ERROR_LINK_TEXT.secondary,
  };
};

export const NO_APPLICATION_OVERVIEW_ERROR = (): Error => {
  return {
    heading: ERROR_HEADINGS.noApplicationOverview,
    linkText: ERROR_LINK_TEXT.secondary,
  };
};
export const NO_SANCTION_CHECKS_ERROR: Error = {
  heading: ERROR_HEADINGS.noSanctionChecks,
};

export const PEPS_AND_SANCTIONS_CHECK_UNDER_PROCESS: Error = {
  heading: ERROR_HEADINGS.sanctionCheckUnderProcess,
};

export const CUSTOMER_DETAILS_ERROR = (url: string): Error => {
  return {
    heading: ERROR_HEADINGS.noCustomerDetails,
    linkText: ERROR_LINK_TEXT.secondary,
    errorLink: url,
  };
};

export const APPLICATION_DETAILS_ERROR = (url: string): Error => {
  return {
    heading: ERROR_HEADINGS.noApplication,
    linkText: ERROR_LINK_TEXT.standard,
    errorLink: url,
  };
};

export const NO_TRANSACTIONS = (): Error => {
  return {
    heading: ERROR_HEADINGS.noTransactions,
  };
};

export const NO_LINK_ERROR = (): Error => {
  return {
    heading: ERROR_HEADINGS.standard,
  };
};

export const NO_CREDIT_REPORT_ERROR: Error = {
  heading: ERROR_HEADINGS.noCreditReport,
};

export const NO_ID_DOCUMENT_REPORT_ERROR: Error = {
  heading: ERROR_HEADINGS.noIdDocumentVerificationReport,
};

export const CUSTOMER_STANDARD_ERROR: Error = {
  heading: ERROR_HEADINGS.standard,
  subText: ERROR_LINK_TEXT.tryLater,
};
export const NODECISION_SANCTION_CHECK_ERROR: Error = {
  heading: ERROR_HEADINGS.noDecisionSanctionCheck,
};
export const NODECISION_ID_DOCUMENT_VERIFICATION_CHECK_ERROR: Error = {
  heading: ERROR_HEADINGS.noDecisionIdDocumentVerificationCheck,
};

export const NODECISION: Error = {
  heading: ERROR_HEADINGS.noDecision,
};
export const NO_DOCUMENT_ID_DOCUMENT_VERIFICATION_CHECK_ERROR: Error = {
  heading: ERROR_HEADINGS.noDocumentFoundIdDocumentVerificationCheck,
};

export const NO_DOCUMENT_IMAGE_ID_DOCUMENT_VERIFICATION_CHECK_ERROR: Error = {
  heading: ERROR_HEADINGS.noDocumentImageFoundIdDocumentVerificationCheck,
};

export const CUSTOMER_APPLICATIONS_ERROR: Error = {
  heading: ERROR_HEADINGS.noApplications,
};

export const NO_DEPARTMENTS_ERROR: Error = {
  heading: ERROR_HEADINGS.noDepartmentsAvailable,
};

export const NO_PERSONS_ERROR: Error = {
  heading: ERROR_HEADINGS.noPersonsFound,
};
