export enum DepartmentFeatures {
  OpenBankingStatementReport = 'OpenBankingStatementReport',
  JointApplication = 'JointApplication',
  AccountsOnDemandRefresh = 'AccountsOnDemandRefresh',
  CategorySpotlight = 'CategorySpotlight',
  WorkReport = 'WorkReport',
  WorkReportHybrid = 'WorkReportHybrid',
  HmrcPortal = 'HmrcPortal',
  OpenBanking = 'OpenBanking',
  JointApplicationsMultipleDepartmentReference = 'JointApplications.MultipleDepartmentReference',
  PortalCustomers = 'PortalCustomers',
  CoverageChecker = 'WorkReport.CoverageChecker',
  PepsAndSanctions = 'PepsAndSanctionsAffordabilityPortal',
  CreditSearch = 'CreditReportCustomerJourney',
  IdDocVerification = 'IdDocVerificationPortal',
  IdDocVerificationPassport = 'IdDocVerificationPassport',
  CreditReportPortalDirect = 'CreditReportPortalDirect',
  MonthlyOpenBankingStatement = 'MonthlyOpenBankingStatement',
}
